import React, {Component} from 'react';
import {GAME_CENTER_URL} from "../constants/config";
import Unity, {UnityContent} from "react-unity-webgl";
import Loader from "./Loader";
import api from "../services/api";

class Game extends Component {


    constructor(props) {
        super(props);
        this.unityContent = new UnityContent(
            `${GAME_CENTER_URL}/${props.game}/Build/build.json`,
            `${GAME_CENTER_URL}/common/UnityLoader.js`,
        );

        this.state = {
            loading: 0,
            loaded: false
        }
    }


    componentDidMount() {

        this.unityContent.on('progress', progression => {
            this.setState({
                loading: progression * 100
            });
        });

        this.unityContent.on('loaded', () => {
            setTimeout(() => {
                this.setState({
                    loaded: true
                });
            }, 2500)
        });

        document.addEventListener('game_quit', () => {
            api.get(`games/quit?user_token=${this.props.userIdentifier}`);
        })
    }

    render() {
        return (<div style={{height: '100vh', width: '100vw'}}>
            <Unity width={'100%'} height={'100%'}
                   unityContent={this.unityContent}/>
            {!this.state.loaded && <Loader game={this.props.game} progress={this.state.loading}/>}
        </div>)
    }
}

export default Game;
