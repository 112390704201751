import axios from 'axios';
import {API_URL, API_KEY} from "../constants/config";

const api = axios.create({
    baseURL: API_URL,
    timeout: 30000
});

// Since we will only be using JSON APIs, add Content-Type: application/json to header as default
api.defaults.headers.post['Content-Type'] = 'application/json';
api.defaults.headers.put['Content-Type'] = 'application/json';
api.defaults.headers.patch['Content-Type'] = 'application/json';

// Since we will only be using JSON APIs, add Accept: application/json to header as default
api.defaults.headers.get.Accept = 'application/json';
api.defaults.headers.post.Accept = 'application/json';
api.defaults.headers.put.Accept = 'application/json';
api.defaults.headers.patch.Accept = 'application/json';

api.defaults.headers.common['x-authorization'] = API_KEY ;

export default api;
