import React from 'react';
import './App.css';
import GAMES from "./constants/games";
import Game from "./components/Game";

function App() {
    const urlParams = new URLSearchParams(window.location.search);

    if (urlParams.has('game') && urlParams.has('user_id') && urlParams.has('organization_id') && GAMES.includes(urlParams.get('game'))) {
        const game = urlParams.get('game');

        return (<div style={{height: '100vh', width: '100vw'}}>
            <Game game={game} userIdentifier={urlParams.get('user_id')}/>
        </div>)
    }

    return (<p>Erreur lors du chargement du jeu</p>)
}

export default App;
