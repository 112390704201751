import React from 'react';
import {GAME_CENTER_URL} from "../constants/config";

const Loader = ({game, progress}) => (
    <div id="game-loader">
        <img className="game-loader__logo" src={`${GAME_CENTER_URL}/${game}/assets/icon-game.png`} alt={'Game logo'}/>
        <div className="game-loader__spinner"/>
        <div className="game-loader__progress">
            <div className="bar" style={{width: progress + '%'}}/>
        </div>
    </div>
);

export default Loader;
